import { useDataPackagesModalStore } from "@/store/modals/dataPackagesModalStore.ts";
import { Dialog, DialogContent } from "@/components/ui/dialog.tsx";
import { H3 } from "@/components/atoms/text/Headings.tsx";
import Text from "@/components/atoms/text/Text.tsx";
import Group from "@/components/atoms/Group.tsx";
import { NOKFormat } from "@/lib/utils/parsers/numParsers.ts";
import Stack from "@/components/atoms/Stack.tsx";

const DataPackagesModal = () => {
  const { isOpen, close, provider } = useDataPackagesModalStore();

  return (
    <Dialog
      modal={true}
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) close();
      }}
    >
      <DialogContent
        className={
          "flex flex-col h-full md:h-auto overflow-y-auto bg-transparent backdrop-blur-md backdrop-filter bg-opacity-50"
        }
      >
        <H3>Datapakker for {provider?.name}</H3>
        {provider?.dataPackages?.map((dataPackage) => (
          <Group key={dataPackage._key} justify={"apart"} w={"full"} grow>
            <Text className={"min-w-[8rem]"}>{dataPackage.gb} GB</Text>
            <Stack gap={"xs"} align={"right"}>
              <Text fw={"semi"}>{NOKFormat(dataPackage.price)}</Text>
              <Text size={"xs"} variant={"subtle"}>
                {dataPackage.notes}
              </Text>
            </Stack>
          </Group>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DataPackagesModal;
